<template>
  <div class="storyblok-input-textarea">
    <AtomInputTextarea
      :label="label"
      :name="name"
      :is-required-val="isRequired"
      @set-input="handleData"
    />
  </div>
</template>

<script setup>
defineProps({
    label: {
        type: String,
        default: '',
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    initialValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
    isRequired: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

const emit = defineEmits(['set-input', 'set-error']);

const handleData = (data) => {
    emit('set-input', data);
};
</script>

<style lang="scss" scoped>
.storyblok-input-textarea {
    width: 100%;
}

</style>
